import { BaseSymbol } from "@/api/model";
import dayjs from "dayjs";
import { defineStore, Store, StoreDefinition, storeToRefs } from "pinia";
import { useMainStore } from ".";
import i18n from "@/locales/index";

const t = i18n.global.t;

export interface DashBoardTab {
  title: string;
  key: string;
  value: BaseSymbol;
  closable: boolean;
}

export const useDashBoardStore = defineStore("dashboard", {
  persist: true,
  state: () => {
    return <
      {
        currentDashBoard: string;
        dashBoardList: DashBoardTab[];
        searchSymbolList: BaseSymbol[];
        symbolsAI?: BaseSymbol[];
        NowChart: string;
        NowChartSaveLoadAdapter: string;
        currentCommandTab: string;
        currentPositionAssetApiIdList: number[];
        NowSyncMode: boolean;
        NowQuickMode: boolean;
        NowAffixedIntervals: [];
        spotLeverageActivate: boolean;
        spotLeverageAutoLoan: boolean;

        NowSyncRatio?: number; //同步比例
      }
    >{
      currentDashBoard: "BINANCE/BTCUSDT/SPOT",
      dashBoardList: [
        {
          title: "BINANCE/BTCUSDT-" + t("dashboardTrade.Spot"),
          key: "BINANCE/BTCUSDT/SPOT",
          value: {
            exchange: "BINANCE",
            currency: "BTC",
            valuation: "USDT",
            account_type: "SPOT",
            symbol: "BTCUSDT",
          },
          closable: true,
        },
        {
          title: "OKX/BTC-USDT-" + t("dashboardTrade.Spot"),
          key: "OKX/BTC-USDT/SPOT",
          value: {
            exchange: "OKX",
            currency: "BTC",
            valuation: "USDT",
            account_type: "SPOT",
            symbol: "BTC-USDT",
          },
          closable: true,
        },
        {
          title: "BYBIT/BTCUSDT-" + t("dashboardTrade.Spot"),
          key: "BYBIT/BTCUSDT/spot",
          value: {
            exchange: "BYBIT",
            currency: "BTC",
            valuation: "USDT",
            account_type: "spot",
            symbol: "BTCUSDT",
          },
          closable: true,
        },
      ],
      searchSymbolList: [],
      symbolsAI: undefined,
      NowChart: "",
      NowChartSaveLoadAdapter: "",
      currentCommandTab: "current",
      currentPositionAssetApiIdList: [],
      NowSyncMode: true,
      NowQuickMode: true,
      NowAffixedIntervals: [],
      spotLeverageActivate: false,
      spotLeverageAutoLoan: false,

      NowSyncRatio: 100,
    };
  },

  actions: {
    addDashBoard(symbol: string) {
      let hasSymbol = false;
      const mainStore = useMainStore();
      this.dashBoardList.forEach((value) => {
        if (value.key == symbol) {
          hasSymbol = true;
          return;
        }
      });

      if (!hasSymbol) {
        const s = mainStore.getSymbolByKey(symbol);
        this.dashBoardList.push({
          title: this.getTitleByTabId(symbol),
          key: symbol,
          value: s,
          closable: true,
        });
      }
      this.currentDashBoard = symbol;
    },
    removeDashBoard(symbol: string) {
      let index = -1;
      this.dashBoardList.forEach((v, i) => {
        if (v.key == symbol) {
          index = i;
          return;
        }
      });
      if (index > -1) {
        this.dashBoardList.splice(index, 1);
        if (this.currentDashBoard === symbol) {
          if (index > 0) {
            this.currentDashBoard = this.dashBoardList[index - 1].key;
          } else {
            this.currentDashBoard = this.dashBoardList[0].key;
          }
        }
      }
    },
    replaceDashBorad(oldSymbol: string, newSymbol: string) {
      let index = -1;
      this.dashBoardList.forEach((v, i) => {
        if (v.key == oldSymbol) {
          index = i;
          return;
        }
      });
      const mainStore = useMainStore();
      const s = mainStore.getSymbolByKey(newSymbol);
      this.dashBoardList[index] = {
        title: this.getTitleByTabId(newSymbol),
        key: newSymbol,
        value: s,
        closable: true,
      };
      this.currentDashBoard = this.dashBoardList[index].key;
    },
    initSearchSymbolList() {
      const mainStore = useMainStore();
      const { symbols } = storeToRefs(mainStore);
      if (symbols.value.length == 0) {
        setTimeout(() => {
          this.initSearchSymbolList();
        }, 1000);
        return;
      }
      const newList: BaseSymbol[] = [];
      symbols.value.forEach((v) => {
        newList.push(v);
      });
      const newSortList = newList.sort((a, b) => {
        if (a.symbol > b.symbol) {
          return 1;
        } else {
          return -1;
        }
      });
      this.searchSymbolList = newSortList;
    },
    searchSymbol(text: string, acType: string) {
      const mainStore = useMainStore();
      const { symbols } = storeToRefs(mainStore);
      const newList: BaseSymbol[] = [];
      symbols.value.forEach((v) => {
        if (
          v.symbol.indexOf(text) != -1 ||
          v.symbol.indexOf(text.toUpperCase()) != -1
        ) {
          newList.push(v);
        }
      });
      this.searchSymbolList = newList;
    },
    getTitleByTabId(tab_id: string) {
      const split = tab_id.split("/");
      const exchange = split[0];
      const symbol = split[1];
      const account_type = split[2];
      switch (exchange) {
        case "BINANCE":
          switch (account_type) {
            case "SPOT":
              return `${exchange}/${symbol}-${t("dashboardTrade.Spot")}`;
            case "FUTURE": {
              const futureSplit = symbol.split("_");
              if (futureSplit.length == 2) {
                //当季或者次季
                const time = dayjs(futureSplit[1], "YYMMDD");
                if (time.subtract(90, "day").unix() <= dayjs().unix()) {
                  //example: BINANCE/BTCUSD-当季0628
                  return `${exchange}/${futureSplit[0]}-${t(
                    "dashboardTrade.Currently"
                  )}${time.format("MMDD")}`;
                } else {
                  //example: BINANCE/BTCUSD-次季0927
                  return `${exchange}/${futureSplit[0]}-${t(
                    "dashboardTrade.Quarterly"
                  )}${time.format("MMDD")}`;
                }
              }
              return `${exchange}/${symbol}-${t("dashboardTrade.Perpetual")}`;
            }
            case "SWAP": {
              const swapSplit = symbol.split("_");
              if (swapSplit[1] == "PERP") {
                return `${exchange}/${swapSplit[0]}-${t(
                  "dashboardTrade.Perpetual"
                )}`;
              } else {
                const time = dayjs(swapSplit[1], "YYMMDD");
                if (time.subtract(90, "day").unix() <= dayjs().unix()) {
                  //example: BINANCE/BTCUSD-当季0628
                  return `${exchange}/${swapSplit[0]}-${t(
                    "dashboardTrade.Currently"
                  )}${time.format("MMDD")}`;
                } else {
                  //example: BINANCE/BTCUSD-次季0927
                  return `${exchange}/${swapSplit[0]}-${t(
                    "dashboardTrade.Quarterly"
                  )}${time.format("MMDD")}`;
                }
              }
            }
          }
          break;
        case "OKX":
          switch (account_type) {
            case "SPOT": {
              const split = symbol.split("-");
              return `${exchange}/${split[0]}-${split[1]}-${t(
                "OkxAccountType.SPOT"
              )}`;
            }
            case "SWAP": {
              const split = symbol.split("-");
              return `${exchange}/${split[0]}-${split[1]}-${t(
                "OkxAccountType.SWAP"
              )}`;
            }
            case "FUTURES": {
              const split = symbol.split("-");
              return `${exchange}/${symbol}`;
            }
          }
          break;
        case "BYBIT":
          switch (account_type) {
            case "spot": {
              return `${exchange}/${symbol}-${t("OkxAccountType.SPOT")}`;
            }
            case "linear": {
              const split = symbol.split("-");
              if (split.length == 2) {
                return `${exchange}/${symbol}`;
              } else {
                return `${exchange}/${symbol}-${t(
                  "BybitAccountType.Perpetual"
                )}`;
              }
            }
            case "inverse": {
              return `${exchange}/${symbol}-${t("BybitAccountType.Inverse")}`;
            }
          }
          break;
        case "DERIBIT":
          return `${exchange}/${symbol.split("-")[0]} - ${t(
            "Dictionary.OPTION"
          )}`;
        case "INDEX":
          return `${exchange}/${symbol} - ${t("dashboardTrade.Index")}`;
        case "forex":
          return `${exchange}/${symbol.split(".")[0]} - ${t(
            "dashboardTrade.Forex"
          )}`;
        case "Astock":
          return `${t("AstockSymbol.A_" + symbol.replaceAll(".", "_"))} - ${t(
            "dashboardTrade.AStock"
          )}`;
        default:
          return "";
      }
      return "";
    },
    setSymbolsAI(symbolsAI: BaseSymbol[] | undefined) {
      this.symbolsAI = symbolsAI;
    },
    getSymbolsAI() {
      return this.symbolsAI;
    },
    setCurrentCommandTab(tab: string) {
      this.currentCommandTab = tab;
    },
    setSyncMode(syncMode: boolean) {
      this.NowSyncMode = syncMode;
    },
    setQuickMode(quickMode: boolean) {
      this.NowQuickMode = quickMode;
    },
    setNowAffixedIntervals(intervals: []) {
      this.NowAffixedIntervals = intervals;
    },
    setSpotLeverageActivate(activate: boolean) {
      this.spotLeverageActivate = activate;
    },
    setSpotLeverageAutoLoan(autoLoan: boolean) {
      this.spotLeverageAutoLoan = autoLoan;
    },
  },
}) as StoreDefinition<
  "dashboard",
  {
    currentDashBoard: string;
    dashBoardList: DashBoardTab[];
    searchSymbolList: BaseSymbol[];
    symbolsAI?: BaseSymbol[];
    NowChart: string;
    NowChartSaveLoadAdapter: string;
    currentCommandTab: string;
    currentPositionAssetApiIdList: number[];
    NowSyncMode: boolean;
    NowQuickMode: boolean;
    NowAffixedIntervals: [];
    spotLeverageActivate: boolean;
    spotLeverageAutoLoan: boolean;
    NowSyncRatio?: number;
  },
  {},
  {
    addDashBoard(symbol: string): void;
    removeDashBoard(symbol: string): void;
    replaceDashBorad(oldSymbol: string, newSymbol: string): void;
    initSearchSymbolList(): void;
    searchSymbol(text: string, acType: string): void;
    getTitleByTabId(tab_id: string): string;
    setSymbolsAI(symbolsMapAI: BaseSymbol[] | undefined): void;
    getSymbolsAI(): BaseSymbol[];
    setCurrentCommandTab(tab: string): void;
    setSyncMode(syncMode: boolean): void;
    setQuickMode(quickMode: boolean): void;
    setNowAffixedIntervals(intervals: string[]): void;
    setSpotLeverageActivate(activate: boolean): void;
    setSpotLeverageAutoLoan(autoLoan: boolean): void;
  }
>;

export interface KchartData {
  StudyTemplateJson: string;
  ChartJson: string;
}
