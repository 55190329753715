import { ASTOCK_LIST } from "../astock";

export default {
  header: {
    menu: {
      tradingx: "TradingX",
      service: {
        title: "服務",
        subItems: {
          marketmaker: "做市商",
          digitalAssetManagement: "數字資產管理",
          advisory: "顧問服務",
        },
      },
      about: {
        title: "關於",
        subItems: {
          aboutUs: "關於我們",
          aboutCareer: "職業機會",
        },
      },
      contactUs: "聯繫我們",
    },
  },
  home: {
    menuItem: {
      production: "TradingX",
      service: "服務",
      about: "關於",
      contact: "聯繫我們",
    },
    intro: {
      whoWeArePart1: "我們是",
      whoWeArePart2: "全球領先的",
      whoWeArePart3: "數字資產",
      whoWeArePart4: "算法交易",
      whoWeArePart5: "公司",
      whatWeDo:
        "我們通過技術和交易解決加密市場流動性和效率的問題，幫助成熟的投資者實現財務成果並推動加密經濟的發展。",
      tradingVolumn: "交易量",
      scientificBasis: "科學依據",
      client: "客戶",
    },
    services: {
      title: "科技與金融的碰撞",
      content: "構築智能的、動態的加密交易系統，推動資本的高效配置和金融創新。",
      aiResearch: {
        title: "AI研究",
        subtitle: "釋放AI的潛力",
        content:
          "我們深度探索AI，創造卓越算法與訓練模型，利用AI的強大驅動尋找市場機會和規避風險，拓展金融邊界，為算法交易帶來更高的效益和成功。",
      },
      marketmaker: {
        title: "加密做市商",
        subtitle: "市場不可或缺的角色",
        content:
          "作為做市商，我們融合數學、金融學和計算機等專業學科，通過深厚的底蕴和卓越的交易能力為市場提供流動性，增強加密市場的可訪問性，維持市場的穩定和效率。",
      },
      crypto: {
        title: "加密投資",
        subtitle: "財富增長的新范式",
        content:
          "我們擅長量化對沖交易，並保持技術創新和金融革命，為投資人提供機構級服務和更好的投資結果，共同塑造加密金融的未來。",
      },
    },
    cooperatorCarousel: {
      title: "我們與全球領先的企業合作",
    },
    exploration: {
      text: "從這裡開始，了解加密市場的魅力",
    },
  },
  product: {
    header: {
      title: "數字資產管理的最佳搭檔",
      content:
        "頂級的交易儀表板，先進的策略、高質量的執行和深度的流動性聚合，為專業交易者提供白手套服務，在任何周期都能輕鬆交易。",
    },
    products: {
      trade: {
        title: "交易",
        content: {
          aggregateTransaction: {
            subtitle: "聚合交易",
            desc: "鏈接主流交易平台，聚合市場深度，實現同一平台執行複雜的交易計劃。",
          },
          engine: {
            subtitle: "高效執行引擎",
            desc: "極速執行，保持低延遲和高吞吐量，確保交易的即時性和精確性。",
          },
        },
      },
      aiTrade: {
        title: "AI交易",
        content: {
          algorithmFrame: {
            subtitle: "領先的算法框架",
            desc: "融合金融工程、數學和AI等領域的研究，部署了領先的算法交易框架。",
          },
          riskSmartManagement: {
            subtitle: "智慧風險管理",
            desc: "對潛在的市場風險進行量化分析，實時調整策略，保障交易安全。",
          },
          strategyOptimization: {
            subtitle: "自適應策略優化",
            desc: "AI系統根據實時市場快速識別機會與學習，自適應優化策略，確保持續的投資回報。",
          },
          solvingStrategy: {
            subtitle: "高級量化策略",
            desc: "AI技術為量化策略提供決策支持。做市策略借助AI，提供流動性，最大化收益並有效管理風險。",
          },
        },
      },
      accountManagement: {
        title: "統一帳戶管理",
        content: {
          assetManagement: {
            key: "account-1",
            subtitle: "一站式資金管理",
            desc: "跨平台的資產統一管理和結算，簡化資金管理流程。",
          },
          maximizeEfficiency: {
            subtitle: "資金效率最大化",
            desc: "更高效地調度資金，實現資金的最優配置和利用。",
          },
        },
      },
      dataAnalysis: {
        title: "數據分析",
        content: {
          marketInsight: {
            subtitle: "深度市場洞察",
            desc: "深度數據挖掘和分析，形成市場洞察報告，輔助決策。",
          },
          transactionDataAnalysis: {
            subtitle: "交易數據分析",
            desc: "投資報告和反饋，包括投資回報率分析和執行質量評估，全面監控財務狀況和投資組合表現。",
          },
        },
      },
    },
    startup: {
      header: {
        title: "為專業投資人設計的數字資產管理平台",
        content:
          "我們召集了各個學科最聰明的人，創造了探索數字資產管理的一切條件，共同實現我們的野心。",
      },
      list: {
        quantitativeTrading: "AI量化交易",
        deepLiquidity: "深度的流動性",
        customizableStrategy: "可定制的策略",
        investmentAnalysis: "數據與投資分析",
      },
      button: {
        text: "想突破投資邊界嗎？從這裡開始",
      },
    },
  },
  marketMaker: {
    banner: {
      title: {
        part1: "做市商",
        part2: "為市場注入關鍵流動性",
      },
      content: "流動性是衡量資產是否快速交易且不引起價格波動的關鍵指標。",
    },
    intro: {
      title: "關於做市商",
      content:
        "做市商類似於“市場的中樞神經”，它們通過快速響應市場信號，協調買賣雙方的交易需求，確保信息的即時傳遞和交易的順暢執行，為市場創造流動性。做市商通過穩定價格和提供即時交易機會，對市場的穩定和效率至關重要。",
    },
    style: {
      title: "TradingX 的做市風格",
      content:
        "作為加密市場的做市商，TradingX降低資本配置與市場創新的成本，為市場注入關鍵流動性，給予全球交易者、投資機構和市場參與者無縫的交易體驗。",
      styles: {
        flow: {
          subtitle: "流動性",
          content: "與全球領先的交易所深度合作，為其提供更強勁的流動性。",
        },
        dataAnalysis: {
          subtitle: "數據分析",
          content: "強大可靠的數據庫及時智能生成報告，決策更快更準。",
        },
        algorithmTrade: {
          subtitle: "做市算法",
          content:
            "做市算法基於 Avellaneda & Stoikov (AS) 模型，結合最新的 AI 技術，為市場提供一種高效、精確的流動性解決方案。",
        },
        experience: {
          subtitle: "經驗",
          content: "熟悉項目和交易所的交易生態需求，提供定制化服務。",
        },
      },
    },
    tip: "流動性對交易所來說極其重要。通常流動性低的訂單簿價差和波動性都比較大，買家和賣家很難以合適的價格成交，只有確保資產的流動性，買賣雙方才能隨時快速交易。",
    contact: {
      title: "如果您需要",
      lyrics: {
        enhanceLiquidity: {
          title: "增強流動性",
          content: "減少買賣價差，提升交易效率。",
        },
        stabilizePrice: {
          title: "穩定價格",
          content: "緩解價格波動，維持交易生態的穩定性。",
        },
        manageCostsNRisks: {
          title: "管理成本與風險",
          content: "更精確的交易進出點，減少交易滑點。",
        },
        institutionalLevelServices: {
          title: "機構級服務",
          content: "支持大型的交易需求。",
        },
        solution: {
          title: "解決方案",
          content: "解決方案：流動性策略，代幣經濟學，加密投資等諮詢。",
        },
      },
    },
    joinUs: {
      content: "需要一個靠譜的合作夥伴？我們的團隊隨時為您提供幫助！",
      button: {
        text: "聯繫我們",
      },
    },
  },
  digitalAssetManagement: {
    banner: {
      title: "數字資產管理",
      content: "AI算法交易系統的領先性和強勁的流動性帶來持續穩定的投資回報",
    },
    management: {
      title: "資產管理",
      content: {
        part1: "利用我們經過驗證的算法，在任何市場環境下都能獲得投資回報。",
        part2: "我們的服務與支持，讓更多投資者有效地參與數字資產投資。",
      },
      AMIs: {
        am1: {
          subtitle: "流動性與市場接入",
          content:
            "廣泛的市場接入和高效的流動性管理，確保客戶資產在全球範圍內實現最佳配置。",
        },
        am2: {
          subtitle: "AI增強的策略",
          content:
            "我們通過AI增強策略能力，持續優化交易模型，實現超前的洞察和交易。",
        },
        am3: {
          subtitle: "穩健的投資表現",
          content:
            "憑借先進的量化交易策略和執行，無論在任何市場環境都可獲得投資回報。",
        },
        am4: {
          subtitle: "風險管理與合規",
          content:
            "我們的全面風險管理框架結合合規性標準，確保投資的透明性和資產的安全性。",
        },
        am5: {
          subtitle: "第三方托管",
          content: "我們支持第三方托管服務，減少交易對手的風險。",
        },
        am6: {
          subtitle: "定制化投資方案",
          content:
            "我們結合客戶的個人目標和風險偏好提供定制化的投資方案，實現個性化的資產管理。",
        },
      },
    },
    tip: "加密市場以其劇烈的價格波動、複雜的技術和不斷變化的監管環境而聞名，這些因素共同構成了一個高度動蕩和複雜的投資領域。在這樣的背景下，投資者面臨著識別和評估真正投資機會的挑戰。",
    issue: {
      title: "投資者面臨的問題",
      content: {
        c1: "數字資產流動性分散，僅用一個交易所能訪問的市場深度極其有限",
        c2: "市場深度不透明，很難辨別市場數據和報告的真實性，導致投資失誤",
        c3: "執行質量低，難以處理大型交易，容易導致高滑點等風險",
        c4: "面對市場波動時常受情緒驅動，作出非理性決策",
      },
    },
    sciManagement: {
      title: "科學的數字資產管理",
      content:
        "我們運用科學的方法解析數字資產市場，基於精確的交易模型進行戰略性投資，即使在熊市也獲得穩定的投資回報，為客戶帶來持續的財富增長。",
      SMs: {
        sm1: {
          subtitle: "算法執行",
          content: "自動遵循交易規則，高質量執行，減少人為失誤。",
        },
        sm2: {
          subtitle: "交易系統",
          content:
            "我們的系統確保了低延遲、高吞吐量的全球交易，保障卓越交易性能。",
        },
        sm3: {
          subtitle: "數據網絡",
          content: "辨別複雜的市場，是投資決策的可靠依據。",
        },
        sm4: {
          subtitle: "多元化策略",
          content:
            "因子研發是我們的專長，結合AI形成可預測，可自主優化的多元化策略。",
        },
        sm5: {
          subtitle: "風險管理",
          content: "完善的風控系統即時監控策略表現，確保投資計劃穩定進行。",
        },
        sm6: {
          subtitle: "白手套服務",
          content: "24x7機構級白手套服務，為客戶提供安心的數字資產投資體驗。",
        },
      },
    },
    contact: {
      text: "如果您需要一個值得信賴的合作夥伴，請聯繫我們。",
      button: {
        text: "聯繫我們",
      },
    },
  },
  advisory: {
    banner: {
      title: "顧問服務",
      content1: "我們為棘手的問題找到優雅的解決方案",
      content2:
        "TradingX提供深入且全面的顧問服務，憑借豐富的專業知識，行業洞見和先進的技術，我們有效地把握市場動態，從客戶利益出發，持續為客戶創造價值。我們顧問服務的對象主要是金融機構，項目和高淨值人群。",
    },
    advisory: {
      AIs: {
        FI: {
          subtitle: "金融機構",
          subContent:
            "我們了解金融機構在加密行業探索中的問題：即市場的複雜性、未來的不確定性以及跨學科知識的挑戰。我們具備深厚的傳統金融經驗和多學科的視野與創新力，為金融機構量身定制個性化的解決策略，幫助金融機構在變幻莫測的加密市場中精準識別風險、捕捉機遇，實現資產的穩健增長與長期的成功。",
          subItems: {
            fi1: {
              name: "進入加密市場",
            },
            fi2: {
              name: "投資戰略建議",
            },
            fi3: {
              name: "算法交易",
            },
            fi4: {
              name: "金融科技研究",
            },
            fi5: {
              name: "風險管理",
            },
            fi6: {
              name: "流動性管理",
            },
          },
        },
        project: {
          subtitle: "項目",
          subContent:
            "我們為項目方提供全面的咨詢和定制方案，以應對代幣發行和市場生命周期管理中的挑戰。通過我們的深度流動性和技術，實現更高的市場占用率和風險管理。我們與有野心的，計劃在一流交易所上市的項目合作。",
          subItems: {
            p1: {
              name: "流動性管理",
            },
            p2: {
              name: "代幣經濟學建議",
            },
            p3: {
              name: "投研與分析",
            },
            p4: {
              name: "生態系統建設",
            },
            p5: {
              name: "風險管理",
            },
            p6: {
              name: "財務方案",
            },
          },
        },
        HNWI: {
          subtitle: "高淨值人群",
          subContent:
            "加密貨幣投資雖然充滿潛力，但也伴隨著一系列疑慮和挑戰。市場的不透明性、技術的複雜性以及資產管理的專業性，往往讓個人投資者感到困惑和不安。我們擅長解決這些困惑，降低加密投資門檻，減少投資者的參與摩擦。",
          subItems: {
            hnwi1: {
              name: "市場洞察與分析",
            },
            hnwi2: {
              name: "定制化資產管理",
            },
            hnwi3: {
              name: "算法交易",
            },
            hnwi4: {
              name: "金融科技研究",
            },
            hnwi5: {
              name: "風險管理",
            },
            hnwi6: {
              name: "24/7專屬客服",
            },
          },
        },
      },
    },
    contact: {
      left: {
        h1: "讓TradingX成為推動您數字資產戰略發展的加速器。",
        button: "聯繫我們",
      },
      right: {
        h1: "關聯服務",
        list: {
          l1: "TradingX",
          l2: "做市商",
          l3: "資產管理",
        },
      },
    },
  },
  aboutUs: {
    banner: {
      left: {
        title: "了解TradingX",
        content1:
          "TradingX是一家領先的算法交易公司，我們通過算法增強市場的無縫運作和可訪問性，拓展金融的邊界。作為全球流動性提供者，我們持續為市場注入活力，推動加密市場的效率，透明度和創新。",
        content2:
          "我們的成員專注於技術研發和金融創新，是充滿活力的問題解決者，我們相信加密金融的未來，並保持耐心與創造。",
      },
      right: {
        stats: {
          stat1: {
            name: "交易量",
          },
          stat2: {
            name: "科學依據",
          },
          stat3: {
            name: "客戶",
          },
          stat4: {
            name: "客戶服務",
          },
        },
      },
    },
    duty: {
      title: "我們的使命",
      content1: "TradingX是全球領先的算法交易公司，",
      content2:
        "我們的使命不斷拓展金融邊界，賦能市場參與者，促進市場的透明度、公平性和效率。",
    },
    values: {
      header: {
        title: "我們的價值觀",
        content:
          "我們的價值觀匯聚了全球志同道合的人才，緊密協作創造領先的算法交易生態，共同促進去中心化世界的繁榮。",
      },
      valuesItems: {
        v1: {
          title: "誠信",
          content: "我們以最高的道德和法律標準為行為準則，保持真誠和負責任。",
        },
        v2: {
          title: "精確",
          content:
            "堅持精確，細致入微的態度，追求在研究、決策和執行過程中的高標準和高質量。",
        },
        v3: {
          title: "開放",
          content:
            "我們擁有開放的工作環境，倡導多元思維的碰撞與跨學科交流，組建卓越團隊。",
        },
        v4: {
          title: "創新",
          content:
            "我們敢於突破邊界，探索未知，通過創新不斷自我超越，推動行業進步。",
        },
        v5: {
          title: "獲勝",
          content:
            "我們不斷精進，提升勝率，在市場博弈中戰勝對手。不斷探索新戰略，幫客戶戰勝難題。",
        },
        v6: {
          title: "互惠",
          content:
            "我們與客戶、合作夥伴及成員建立互惠共贏的長期合作，共同分享成長與成功。",
        },
      },
    },
    ourTeam: {
      header: {
        title: "我們的綜合團隊",
        content:
          "我們的團隊由金融工程、數學科學、工程等領域的頂級人才構成，推動金融科技革新和實現共同的使命。",
      },
      ourTeamItems: {
        ot1: {
          title: "AI科研",
          content:
            "我們的專家團隊致力於AI技術的研究，以實現交易算法的自我優化和市場適應性的提升。",
        },
        ot2: {
          title: "工程",
          content:
            "我們的工程團隊專注於構建可擴展、高可靠性的基礎設施，確保系統的高可用性和低延遲，滿足大規模交易的需求。",
        },
        ot3: {
          title: "市場與交易",
          content:
            "市場與交易團隊憑借對市場的深刻洞察，執行精確的交易操作，同時管理和對沖市場風險，以實現穩健的交易表現。",
        },
        ot4: {
          title: "量化研究",
          content:
            "量化研究團隊擅長數學模型和統計，在動蕩市場中的發現細微的聯繫，構建驅動交易的複雜算法。",
        },
        ot5: {
          title: "商業咨詢",
          content:
            "我們的商業咨詢團隊提供定制化解決方案，幫助客戶理解市場和制定策略，以實現商業目標。",
        },
      },
    },
    solve: {
      header: {
        title: "我們解決了什麼",
        content: "通過我們的專業，解決複雜的市場問題",
      },
      solveArounds: {
        sa1: {
          name: "研發與部署",
        },
        sa2: {
          name: "風險管理",
        },
        sa3: {
          name: "投研與分析",
        },
        sa4: {
          name: "數字資產管理",
        },
        sa5: {
          name: "代幣經濟學建議",
        },
        sa6: {
          name: "流動性管理",
        },
        sa7: {
          name: "生態建議",
        },
        sa8: {
          name: "定制化服務",
        },
      },
    },
  },
  aboutCareer: {
    banner: {
      h1: "職業機會",
      h2: "創造迭代人生策略，實現自己的野心。",
      p1: {
        part1: "我們匯聚來自不同背景的人才，共同打造一個自由的、多元化的團隊。",
        part2: "在探索未知和挑戰對手無法解決的難題的過程中，實現我們的抱負。",
      },
      p2: "我們野心勃勃並且對加密行業充滿熱情，如果您也一樣，請申請！",
    },
    job: {
      header: {
        title: "開放職位",
      },
      jobs: {
        quantitativeResearcher: {
          name: "量化研究員",
        },
        engineer: {
          name: "資深工程師",
        },
        trader: {
          name: "交易員",
        },
        applyButton: {
          text: "申請",
        },
      },
    },
    careers: {
      quantitativeResearcher: {
        name: "量化研究員",
        remote: {
          location: "香港",
          mode: "全職",
        },
        applyButton: {
          text: "申請職位",
        },
        finding: {
          f1: "我們尋找的是熱愛數學並擅長撰寫自動交易算法的人。您應在創造和回測上有天賦，擅長生成Alpha和自動化策略。您將與交易員和開發人員密切合作，提出假設、收集數據並進行分析以開發新算法。",
          f2: "TradingX正在尋找資深量化研究員，這是一個加入全球領先算法交易公司難得的機會，我們重視不同背景的、有創造力的人才，並鼓勵他們充分發揮自己的潛力。在TradingX，您將有機會與各個領域的天才們一起解決有意義的問題，並成為前沿領域的專家。我們努力照顧我們的員工，提供有競爭力的激勵、福利和獎勵。",
        },
        candidate: {
          title: "我們的理想候選人：",
          needs: {
            n1: {
              content: "2年以上量化研究經驗，特別是在做市商定價和對沖策略方面",
            },
            n2: {
              content:
                "擁有計量經濟學、數學、物理學、統計學、計算機科學或相關學科的碩士以上學位",
            },
            n3: {
              content:
                "對數字資產的市場微觀結構有深刻的理解，精通至少一種編程語言，如Java，Python或C++",
            },
            n4: {
              content: "具備低延遲交易系統的經驗、強大的數學和統計能力",
            },
            n5: {
              content: "出色的邏輯思維和問題解決能力，能在壓力下工作並承擔責任",
            },
            n6: {
              content:
                "敢於競爭，持續學習，積極主動，擅於協作，優秀的溝通技巧，英語能力強優先",
            },
          },
        },
        todos: {
          title: "您需要做的是：",
          td1: {
            content: "與交易員和工程團隊合作，優化和創新交易策略",
          },
          td2: {
            content: "對數據進行深入研究和分析，識別和利用市場模式",
          },
          td3: {
            content: "設計和實施複雜的量化模型和自動化交易算法",
          },
        },
      },
      engineer: {
        name: "資深工程師",
        remote: {
          location: "香港",
          mode: "全職",
        },
        applyButton: {
          text: "申請職位",
        },
        finding: {
          f1: "我們的工程師與研究員和交易員合作，解決大規模複雜問題。我們的團隊能夠快速將想法付諸實施，市場也能迅速提供反饋。",
          f2: "TradingX正在尋找資深工程師，這是一個加入全球領先算法交易公司難得的機會，我們重視不同背景的、有創造力的人才，並鼓勵他們充分發揮自己的潛力。在TradingX，您將有機會與各個領域的天才們一起解決有意義的問題，並成為前沿領域的專家。我們努力照顧我們的員工，提供有競爭力的激勵、福利和獎勵。",
        },
        candidate: {
          title: "我們的理想候選人：",
          needs: {
            n1: {
              content:
                "計算機科學、工程學、統計學、數學、物理學或相關領域的碩士學位",
            },
            n2: {
              content: "卓越的設計和編程技能，具備多種編程語言的能力",
            },
            n3: {
              content: "能夠在複雜且技術含量高的團隊環境中協助和有效溝通",
            },
            n4: {
              content: "對利用技術解決挑戰性問題的好奇心和熱情",
            },
            n5: {
              content: "具備較強的分析能力，熟悉概率和統計學",
            },
            n6: {
              content: "曾在金融科技公司工作，能進行區塊鏈開發",
            },
          },
        },
        todos: {
          title: "您需要做的是：",
          td1: {
            content: "設計和開發金融市場產品，包括低延遲算法交易平台",
          },
          td2: {
            content: "實施、驗證並執行軟件測試",
          },
          td3: {
            content: "支持研究員和交易員，確保算法的實施",
          },
          td4: {
            content: "創建並維護文檔，編寫功能和設計規範",
          },
          td5: {
            content: "根據需求改進和定制系統",
          },
          td6: {
            content: "編碼開發和調試，包括相關的自動化單元和集成測試",
          },
        },
      },
      trader: {
        name: "交易員",
        remote: {
          location: "香港",
          mode: "全職",
        },
        applyButton: {
          text: "申請職位",
        },
        finding: {
          f1: "我們的交易員與研究員和工程師合作，共同將偉大的想法付諸實踐，取得更好的結果。",
          f2: "TradingX正在尋找交易員，這是一個加入全球領先算法交易公司難得的機會，我們重視不同背景的、有創造力的人才，並鼓勵他們充分發揮自己的潛力。在TradingX，您將有機會與各個領域的天才們一起解決有意義的問題，並成為前沿領域的專家。我們努力照顧我們的員工，提供有競爭力的激勵、福利和獎勵。",
        },
        candidate: {
          title: "我們的理想候選人：",
          needs: {
            n1: {
              content: "金融、經濟學、數學、統計學或相關領域的碩士學位",
            },
            n2: {
              content: "至少2年加密行業交易經驗",
            },
            n3: {
              content: "對金融市場和交易策略有廣泛的了解，尤其是做市",
            },
            n4: {
              content: "強大的分析、定量和人際交往能力",
            },
            n5: {
              content: "具備評估新代幣相關的風險和機會的能力",
            },
            n6: {
              content:
                "能夠管理複雜的交易和頭寸，並隨時了解加密市場趨勢和監管變化",
            },
            n7: {
              content: "敢於競爭，持續學習，積極主動，擅於協作",
            },
            n8: {
              content: "優秀的溝通技巧，英語能力強優先",
            },
          },
        },
        todos: {
          title: "您需要做的是：",
          td1: {
            content: "具備豐富的數字資產交易知識以及在全球交易所進行交易的經驗",
          },
          td2: {
            content: "與ICO發行人合作，促進一級市場交易並確保及時高效執行",
          },
          td3: {
            content: "確定潛在的做市機會和策略優化",
          },
          td4: {
            content: "洞察市場，制定計劃，識別風險並大規模執行複雜交易",
          },
          td5: {
            content: "提高我們數字資產業務的整體盈利能力",
          },
        },
      },
      application: {
        title: "填寫申請表",
        applyForm: {
          candidateInfo: {
            h1: "候選人資訊",
            name: {
              label: "名稱",
              placeholder: "請問如何稱呼您",
            },
            resume: {
              label: "簡歷/履歷",
              placeholder: "上傳您的簡歷或履歷 (.pdf, .doc)",
            },
            phone: {
              label: "電話",
              placeholder: "您的手機號碼",
            },
            email: {
              label: "郵箱",
              placeholder: "您的郵箱地址",
            },
            currentCompany: {
              label: "現就職公司",
              placeholder: "您的公司名稱",
            },
          },
          candidateLinks: {
            h1: "連結",
            linkedIn: {
              label: "LinkedIn網址",
              placeholder: "您的LinkedIn網址",
            },
            github: {
              label: "Github網址",
              placeholder: "您的Github網址",
            },
            combine: {
              label: "組合網址",
              placeholder: "您的組合網址",
            },
            other: {
              label: "其他網址",
              placeholder: "您的其他網址",
            },
          },
          candidateExtra: {
            h1: "附加資訊",
            p: "添加求職信或任何其他您想要分享的內容",
          },
          submitButton: {
            text: "提交申請表",
          },
          notification: {
            submit: {
              success: {
                message: "提交成功",
                description: "您的申请表已經成功提交！",
              },
              error: {
                message: "提交失敗",
                description: "申請表提交失敗，請聯繫管理員。",
              },
            },
          },
          Rules: {
            ResumeRule: {
              IsNotNull: "簡歷/履歷不能為空",
              IsOversize: "簡歷文件過大，上限10M",
            },
          },
        },
      },
    },
  },
  contactUs: {
    banner: {
      text: "聯繫TradingX",
    },
    contact: {
      h1: "請填寫表單與我們聯繫",
      formItems: {
        firstName: {
          placeholder: "名*",
        },
        lastName: {
          placeholder: "姓*",
        },
        email: {
          placeholder: "電子郵件*",
        },
        telegram: {
          placeholder: "電報*",
        },
        company: {
          placeholder: "公司*",
        },
        position: {
          placeholder: "職位",
        },
        interest: {
          placeholder: "我感興趣的是*",
          options: {
            opt1: {
              name: "TradingX",
            },
            opt2: {
              name: "資產管理",
            },
            opt3: {
              name: "做市商",
            },
            opt4: {
              name: "合作夥伴關係",
            },
          },
        },
        business: {
          placeholder: "業務*",
          options: {
            opt1: {
              name: "對沖基金",
            },
            opt2: {
              name: "風險投資基金",
            },
            opt3: {
              name: "機構財富管理人",
            },
            opt4: {
              name: "金融中介",
            },
            opt5: {
              name: "自營交易者",
            },
            opt6: {
              name: "金融基礎設施提供商",
            },
            opt7: {
              name: "高淨值個人",
            },
            opt8: {
              name: "其他",
            },
          },
        },
        assetRange: {
          placeholder: "管理資產範圍*",
          options: {
            opt1: {
              name: "<1M",
            },
            opt2: {
              name: "1M-10M",
            },
            opt3: {
              name: "10M-100M",
            },
            opt4: {
              name: "100M-500M",
            },
            opt5: {
              name: "500M-1B",
            },
            opt6: {
              name: ">1B",
            },
          },
        },
        country: {
          placeholder: "國家*",
        },
        submitButton: {
          text: "提交",
        },
      },
    },
  },
  footer: {
    media: {
      title: "社交媒體",
    },
    production: {
      title: "產品",
      tradingx: "TradingX",
    },
    about: {
      title: "關於",
      aboutUs: "關於我們",
      career: "職業機會",
    },
    service: {
      title: "服務",
      marketMaker: "做市商",
      digitalAssetManagement: "數字資產管理",
      consultationService: "顧問服務",
      serviceObject: "",
    },
    contact: {
      title: "聯繫我們",
      contact: "聯繫",
    },
  },
  sider: {
    trade: "交易",
    account: "賬戶",
    AITrade: "AI交易",
    TradeData: "交易數據",
    data: "數據",
    Settings: "設置",
  },
  dashboardTrade: {
    PleaseSelectPosition: "請選擇倉位",
    AllClosePosition: "所選一鍵平倉",
    SyncMode: "同步下單",
    Cont: "張",
    IsHideMin: "隱藏小額資產",
    Asset: "資產",
    Query: "查詢",
    OpenLong: "開多",
    CloseShort: "平空",
    open: "開倉",
    close: "平倉",
    Spot: "現貨",
    Index: "指數",
    Futures: "期貨",
    Forex: "外匯",
    AStock: "A股",
    CloseLong: "平多",
    OpenShort: "開空",
    USDFutures: "U本位合約",
    COINFutures: "幣本位合約",
    Perpetual: "永續",
    Quarterly: "次季",
    Currently: "當季",
    DayChange: "24h 漲跌",
    DayHigh: "24h 最高價",
    DayLow: "24h 最低價",
    DayVolumeone: "24h 成交量",
    DayVolumetwo: "24h 成交額",
    Price: "價格",
    Amount: "數量",
    Total: "總額",
    Buy: "買入",
    Sell: "賣出",
    Limit: "限價",
    MaxBuy: "可開",
    Available: "可用",
    Balance: "餘額",
    AcceptQuotesSpread: "允許價差",
    QuickMode: "快速模式",
    Market: "市價",
    LogIn: "前往登錄",
    TriggerOrder: "計劃委託",
    TriggerPrice: "觸發價",
    Tradeatthebestprice: "以市場最優價成交",
    ActivateAccount: "請先啟動帳戶",
    SwitchMainAccount: "請先切換主帳戶",
    Markets: "行情",
    Depth: "深度",
    OpenOrders: "當前委託",
    SubAccount: "子帳戶",
    Pair: "交易對",
    Time1: "日期",
    Side: "方向",
    Amount1: "委託量",
    Total1: "委託額",
    FilledAmount: "成交量",
    TotalFilled: "成交額",
    AveragePrice: "成交均價",
    ExecutionTime: "執行時間",
    Status: "執行狀態",
    OrderStatus: "訂單狀態",
    TimeInForce: "訂單有效方式",
    ImpactPercent: "衝擊率",
    OrderType: "訂單類型",
    OrderQty: "數量",
    PositionSide: "持倉方向",
    TradeRate: "成交率",
    Action: "操作",
    CancelAllOrder: "一鍵撤單",
    OrderHistory: "歷史委託",
    PositionHistory: "歷史平倉",
    AveragePositionCost: "倉位成本均價",
    AverageClosePrice: "平倉均價",
    CloseAmount: "平倉数量",
    RealizedPnL: "已實現盈虧(USD)",
    Position: "倉位",
    Typenew: "倉位類型",
    OpenPositions: "當前持倉",
    AverageOpenPrice: "开倉均價",
    MarkPrice: "標記價格",
    Margin: "保證金",
    MarginRatio: "保證金率",
    UnrealizedPnL: "未實現盈虧",
    ExchangePosition: "交易所倉位",
    ExchangeOpenOrder: "交易所掛單",
    OpenOrder: "當前掛單",
    LiqPrice: "強平價格",
    Time: "委託時間",
    Type: "委託類型",
    CurrentTokensOnly: "隱藏其他幣種",
    TriggerOrderLimit: "條件委託限價",
    TriggerOrderMarket: "條件委託市價",
    BaseVersion: "尊享版",
    ProVersion: "專業版",
    Details: "詳情",
    Revoke: "撤單",
    NineRounds: {
      Name: "九轉局",
      NineRoundsA: {
        Name: "九轉局A",
        TwoPointMethod: "二點法",
        ThreePointMethod: "三點法",
      },
      NineRoundsD: {
        D1Name: "九轉局D1",
        D2Name: "九轉局D2",
        SpaceMethod: "空間法",
        TimeMethod: "時間法",
      },
      Delete: "刪除圖",
      MessageText: {
        RenderError: "無法繪製九轉局，請重新切換時間或重新繪製",
        Open: "開",
        High: "高",
        Low: "低",
        Close: "收",
        Volume: "成交量",
        FirstPoint: "第一點",
        SecondPoint: "第二點",
        ThirdPoint: "第三點",
        NewestPrice: "最新價格",
        RenderTipsClickTwo: "請點擊兩個點",
        RenderTipsClickThree: "請點擊三個點",
        ConfirmRender: "是否確認繪製九轉局?",
        ConfirmReRender: "是否重新繪製九轉局?",
        WillClear: "將清除現有九轉局",
        Time: "時間",
        Price: "價格",
      },
    },
    Layout: {
      Name: "佈局",
      Lock: "移動佈局",
      AutoSave: "自動保存",
      Rename: "重命名",
      NewLayout: "新建佈局",
      MyLayout: "我的佈局",
      RecoverDefault: "恢復默認",
      ConfirmDelete: "確認刪除佈局?",
      ConfirmDeleteTip: "刪除後將無法恢復",
      Cancel: "取消",
      Confirm: "確認",
      SubMap: "分圖",
      BrowserNotSupport: "當前用戶瀏覽器不支持Canvas,請更換瀏覽器重試！",
      SaveSuccess: "保存成功",
      SaveFail: "保存失敗",
      DeleteSuccess: "刪除成功",
      DeleteFail: "刪除失敗",
    },
    RuleMessageTest: {
      NumRule: {
        IsNotNum: "請輸入數字",
        IsIllegalNum: "請輸入合法數字",
        IsNotNull: "不能為空",
      },
      EmailRule: {
        IsIllegalEmail: "請輸入正確的信箱地址",
        IsNotNull: "郵件地址不能為空",
      },
      PhoneRule: {
        IsNotNull: "手机号碼不能為空",
        IsIllegalPhoneNumber: "請輸入正確的手機號碼",
      },
    },

    Deribit: {
      Position: "仓位",
      OpenOrder: "未结订单",
      OrderHistory: "订单历史记录",
      PositionHistory: "仓位历史记录",
    },
  },
  views_login: {
    login: "登錄",
    login_input_userName_placeholder: "請輸入郵箱/手機號",
    login_input_password_placeholder: "請輸入密碼",
    login_input_VerificationCode_placeholder: "請輸入驗證碼",
    login_remember_password: "記住密碼",
    login_forget_password: "忘記密碼？",
    login_register: "立即註冊",
  },
  client_things: {
    TotalVolume: "總成交量",
    WeekVolume: "周成交量",
    TotalMMVolume: "做市商成交總量",
    OrderQuantity: "訂單成交筆數",
    CommisionSaving: "總節省手續費",
    TotalRebate: "返佣總額",
    TotalSaving: "總節省成本",
    TradeNow: "去交易",
    client_present: "讓交易更簡單",
    client_present_long: "數位資產投資管理平臺",
    standard: "BTC現貨已獲得SEC標準",
    standardMore: "詳情",
    WhyTradingX: "TradingX的優勢",
    Executeinaggregation: "高效聚合執行",
    Leadingmarketmakingtools: "領先的做市工具",
    DataAnalysis: "數據分析",
    ComplianceSecurity: "合規風控",
    Executeinaggregationnew:
      "一個介面一鍵執行不同交易所的交易計劃，減少和交易所打交道。",
    Leadingmarketmakingtoolsnew:
      "全自動交易機器人和半自動工具，幫助投資人無論在什麼市場都能贏得優勢。",
    DataAnalysisnew:
      "主流數位資產鏈上數據、DEXes和CEXes的交易數據及全球股市、匯市、黃金等交易數據的分析，幫助優化投資組合。",
    ComplianceSecuritynew: "新加坡和香港證券牌照，讓交易更安心。",
    Announcement: "平臺公告",
    UserGuide: "使用教程",
    ContactJoinUs: "聯系/加盟我們",
  },
  components_myheader: {
    loginout: "退出登錄",
    login: "登錄",
    register: "註冊",
    totalone: "24h交易總量",
    totaltwo: "24hAI交易量",
    totalthree: "24h機器人交易量",
    totalfour: "返佣總額",
    totalfive: "24H做市商交易量",
    referral: "邀請推薦",
    AllRead: "全部已讀",
    NoMore: "沒有更多了",
    TradeMessage: "交易通知",
    Announcement: "公告",
    loadMore: "加載更多",
    SystemMessage: "系統消息",
  },
  components_mysymbolselect: {
    search: "查詢",
  },
  views_signup: {
    email: "郵箱",
    phone: "手機",
    signup_input_userName: "請設定用戶名",
    signup_input_userName_placeholder_email: "請輸入郵箱",
    signup_input_userName_placeholder_phone: "請輸入手機號",
    signup_input_userName_placeholder_Password: "請設置密碼",
    signup_input_userName_placeholder_confirmPassword: "請確認密碼",
    signup_input_userName_placeholder_inviteCode: "請輸入邀請碼",
    signup_input_userName_placeholder_VerificationCode: "請輸入驗證碼",
    signup_input_userName_placeholder_Code: "獲取驗證碼",
    signup_register: "註冊",
    signup_login: "使用已有帳戶登錄",
    signup_signup_success: "註冊成功",
    signup_email_phone: "郵箱/手機號",
    signup_back_login: "返回登錄",
    signup_please_input_password: "請輸入密碼",
    signup_please_input_size_password: "密碼長度為8-20位",
    signup_please_input_confirm_password: "請確認密碼",
  },
  user_info: {
    AccountManagement: "帳戶管理",
    Phone: "手機",
    Level: "帳戶等級",
    Type: "帳戶類型",
    LoginLog: "登錄日誌",
    Add: "新增",
    Exchange: "交易所",
    AccountName: "帳戶名",
    APIKEY: "帳戶APIKEY",
    Secretkey: " 帳戶SecretKey",
    ExpirationTime: "過期時間",
    PermissionList: "權限清單",
    IPList: "IP清單",
    CreateTime: "創建時間",
    UpdateTime: "更新時間",
    Action: "操作",
    ViewBalance: "查看餘額",
    Edit: "編輯",
    Delete: "删除",

    AssetsManagement: "資產管理",
    FinancialAnalysis: "財務分析",

    StrategyAnalysis: "策略分析",
    SelectExchange: "選擇交易所",
    SelectStrategyType: "選擇策略類型",
    SumQty: "總成交額",
    TotalSumQty: "累計成交額",
    SumCost24H: "24H資金成本",
    SumQty24H: "24H成交額",
    MaxHold24H: "24H最大持股",
    MaxHoldLong24H: "24H最大持股(多)",
    MaxHoldShort24H: "24H最大持股(空)",
    SumNetProfit24H: "24H淨利",
    ProfitAndMaxDrawDownChart: "收益與最大回檔",
    NetProfitRate24H: "24H收益率",
    SumNetProfitRate: "累計收益率",
    LowWater: "低水位",
    MaxDrawDown24H: "24H最大回檔",

    NetValueChart: "複合收益率",
    DrawDownChart: "回檔曲線",
    ProfitChart: "盈虧曲線",
    NetValue: "資產淨值",
    HighWater: "高水位",
    DrawDown: "回檔",
    Profit: "盈虧",
    TotalProfit: "累計盈虧",

    Date: "時間",
    Return: "殖利率",
    GrossReturn: "毛報酬率",
    Nav: "淨值",
    Balance: "餘額",
    Transfer: "劃轉",
    LastUpdateTime: "最後更新時間",

    AnnualReturn: "年化報酬率",
    AnnualVolatility: "年化波動率",
    SharpeRatio: "夏普比率",
    MaxDrawDown: "最大回檔",

    Hour: "小時",
    Day: "日",
    Week: "週",
    Month: "月",
    Year: "年",
    TIME_7D: "7天",
    TIME_30D: "30天",
    TIME_90D: "90天",
    TIME_180D: "半年",
    TIME_365D: "一年",
    OTHER: "自訂",

    SelectAccountType: "選擇帳戶類型",
    SelectTimeRange: "選擇時間範圍",
    SelectTimeDuration: "選擇時間間隔",
    SelectSubAccount: "選擇子帳戶",
    SelectStatisticsCurrency: "選擇統計幣種",

    LastDayPnL: "昨日盈虧",
    LastWeekPnL: "7天盈虧",
    LastMonthPnL: "30天盈虧",
    Chart: "圖表",
    DailyPnL: "每日收益",
    CumulativePnL: "累計收益",
    PnLDistribution: "收益分佈",
    Smart: "AI交易",
    Bots: "機器人",
    AssetsDistribution: "資產分佈",
    PositionDistribution: "持倉分佈",

    Traders: "交易員",
    Observer: "觀察者",
    ToVerify: "去驗證",
    ConfirmDelete: "是否確認删除？",
    ConfirmDeleteTips: "此操作無法撤銷！",
    Confirm: "確認",
    Cancel: "取消",

    AccountBalance: "帳戶餘額",
    Currency: "幣種",

    AccountType: "帳戶類型",
    AvailableBalance: "可用餘額",
    LockBalance: "鎖定餘額",
    MarginBalance: "保證金餘額",
    WalletBalance: "錢包餘額",
    MaxTransferableBalance: "最大可轉出餘額",
    Total: "總計",
    Record: "條記錄",

    Account: "帳戶",
    LoginIP: "登錄IP",
    LoginTime: "登錄時間",

    ApiKey: {
      ApiForm: {
        NewAccount: "新增帳戶",
        EditAccount: "編輯帳戶",
        AccountName: "帳戶名",
        Exchange: "交易所",
        AccountApiKey: "帳戶ApiKey",
        AccountSecretKey: "帳戶Secret Key",
        AccountPassPhrase: "帳戶Passphrase",
        AuthList: "權限清單",
        SpotTrade: "現貨交易",
        FutureTrade: "合約交易",
        Reset: "重置",
        Rule: {
          NameMsg: "請輸入帳戶名",
          ExchangeMsg: "請選擇交易所",
          ApiKeyMsg: "請輸入ApiKey",
          SecretKeyMsg: "請輸入SecretKey",
          PassPhraseMsg: "請輸入Passphrase",
          AuthListMsg: "請選擇權限",
        },
        Confirm: "確認",
        Cancel: "取消",
      },
    },
  },
  data_info: {
    Data: "數據",
    Greedindex: "貪婪指數",
  },
  setting_info: {
    System: "系統設置",
    Language: "語言設定",
    Currency: "貨幣設定",
    TimeZone: "時區選擇",
    Notice: "通知設定",
    Trade: "交易通知",
    OpenTradeNotice: "是否開啟交易通知",
    Announcement: "公告",
    OpenAnnouncementNotice: "是否開啟公告通知",
    ON: "開",
    OFF: "關",
    Security: "安全設置",
    BindEmail: "綁定郵箱",
    BindedEmai: "已綁定郵箱",
    SMSVerification: "簡訊驗證",
    EffectivelyProtectAccountSecurity: "簡訊驗證可有效保護帳戶安全",
    EmailVerification: "郵箱驗證",
    EffectivelyProtectEmailSecurity: "郵箱驗證可有效保護帳戶安全",
    BindIP: "綁定IP",
    BindedIP: "已綁定IP",
    Change: "修改",
    ChangePassword: "修改密碼",
    LanguageSetting: {
      SimplifiedChinese: "简体中文",
      TraditionalChinese: "繁體中文",
      English: "English",
    },
    SecuritySetting: {
      AccountPassword: "帳戶密碼",
      PasswordLevel: "当前密碼強度",
      PasswordLevelLow: "低",
      PasswordLevelMedium: "中",
      PasswordLevelHigh: "高",
    },
    PlaceHolderText: {
      OldPassword: "請輸入原密碼",
      NewPassword: "請輸入新密碼",
      ConfirmPassword: "請確認新密碼",
    },
  },
  PlaceHolderText: {
    Cancel: "取消",
    Confirm: "確認",
  },
  Dictionary: {
    EXECUTING: "正在執行",
    EXECUTED: "執行成功",
    CANCELING: "正在撤銷",
    CANCELED: "已撤銷",
    EXECUTION_ERROR: "執行錯誤",
    OPEN: "開倉",
    CLOSE: "平倉",
    BUY: "買入",
    SELL: "賣出",
    LONG: "多",
    SHORT: "空",
    BOTH: "雙向",
    NORMAL_MODE: "正常模式",
    FILLED: "完全成交",
    LIMIT: "限價",
    MARKET: "市價",
    STOP_MARKET: "市價止損",
    TAKE_PROFIT_MARKET: "市價止盈",
    TAKE_PROFIT: "止盈",
    STOP_LOSS: "止損",
    PARTIALLY_FILLED: "部分成交",
    TRADING: "交易中",
    DIRECT_MODE: "直接模式",
    BINANCE_FUNDING: "資金",
    BINANCE_SPOT: "現貨",
    BINANCE_MARGIN_CROSSED: "全倉槓桿",
    BINANCE_MARGIN_ISOLATED: "逐倉槓桿",
    BINANCE_FUTURE: "U本位合約",
    BINANCE_SWAP: "幣本位合約",
    SPOT: "現貨",
    SWAP: "幣本位合約",
    FUTURE: "U本位合約",
    LINEAR: "U本位合約",
    INVERSE: "反向合約",
    spot: "現貨",
    linear: "U本位合約",
    inverse: "反向合約",
    OKX_SPOT: "現貨",
    OKX_SWAP: "永續合約",
    OKX_FUTURES: "交割合約",
    OPTION: "期權",
    INDEX: "指數",
    FUTURES: "期貨",
    FOREX: "外匯",
    ASTOCK: "A股",
    EXECUTION_TIMEOUT: "執行超時",
    UNFILLED: "未成交",
    TRIGGER_ORDER_LIMIT: "條件委託限價",
    TRIGGER_ORDER_MARKET: "條件委託市價",
    WAITING: "待觸發",
    EXPIRED: "失效",
    PLACE_ORDER: "掛單",
    FAILURE: "失敗",
    PREPARING: "準備中",
    STOPPED: "已停止",
    STOPPING: "停止中",
    SELL_CLOSE: "賣出平倉",
    SELL_OPEN: "賣出开倉",
    BUY_CLOSE: "買入平倉",
    BUY_OPEN: "買入开倉",
    CLOSE_LONG: "平多",
    CLOSE_SHORT: "平空",
    INTERRUPTED: "中斷",

    UNIFIED: "統一帳號",
    CONTRACT: "合約帳號",

    SAT_BINANCE_SPOT: "現貨",
    SAT_BINANCE_MARGIN_CROSSED: "全倉槓桿",
    SAT_BINANCE_MARGIN_ISOLATED: "逐倉槓桿",
    SAT_BINANCE_FUTURE: "U本位合約",
    SAT_BINANCE_SWAP: "幣本位合約",
    SAT_OKX_SPOT: "現貨",
    SAT_OKX_SWAP: "永續合約",
    SAT_OKX_FUTURES: "交割合約",
    SAT_BYBIT_spot: "現貨",
    SAT_BYBIT_linear: "U本位合約",
    SAT_BYBIT_inverse: "反向合約",

    AAT_BINANCE_FUNDING: "資金帳戶",
    AAT_BINANCE_SPOT: "現貨帳戶",
    AAT_BINANCE_MARGIN_CROSSED: "全倉槓桿帳戶",
    AAT_BINANCE_MARGIN_ISOLATED: "逐倉槓桿帳戶",
    AAT_BINANCE_FUTURE: "U本位合約帳戶",
    AAT_BINANCE_SWAP: "幣本位合約帳戶",
    AAT_BYBIT_FUNDING: "資金帳戶",
    AAT_BYBIT_UNIFIED: "統一帳戶",
    AAT_BYBIT_CONTRACT: "合約帳戶",
    AAT_OKX_FUNDING: "資金帳戶",
    AAT_OKX_SPOT: "統一帳戶",
  },
  Modal: {
    EmptyModal: {
      Message: "暫無數據",
    },
    InviteModal: {
      Invite: "邀請",
      Cancel: "取消",
      RateOfReturn: "收益率",
      InviteCode: "邀請碼",
      Copy: "複製",
    },

    DeribitModal: {
      Cancel: "取消",
      Call: "看漲",
      Put: "看跌",
      Amount: "數量",
      Position: "持倉",
      LimitPrice: "限價",
      AdvancedOrderForm: "高級委託",
      TimeInForce: "有效期",
      Reduce: "減倉",
      Post: "只做Maker",
      BuyMargin: "買入保證金",
      SellMargin: "賣出保證金",
      ContractDetails: "合約詳情",
      PriceSource: "價格來源",
      ContractSize: "合約大小",
      MinOrderSize: "最小委託量",
      TickSize: "最小價格變動",
      SettlementCurrency: "結算貨幣",
      ExpiryDate: "到期日",
      MinSellPrice: "最小賣出價格",
      MaxBuyPrice: "最大買入價格",
      Delta: "Delta",
      Gamma: "Gamma",
      Vega: "Vega",
      Theta: "Theta",
      Rho: "Rho",
      OrderBook: "訂單簿",
      MarkPrice: "標記價格",
      the24hLow: "24h最低價",
      the24hHigh: "24h最高價",
      the24hVolume: "24h成交量",
      the24hChange: "24h漲跌",
      Total: "總計",
      BidSize: "買入量",
      BidPrice: "買入價",
      AskPrice: "賣出價",
      AskSize: "賣出量",
      RecentTrade: "最新成交",
      Chart: "圖表",
      Buy: "買入",
      Sell: "賣出",
    },
    WarningModal: {
      PriceAlert: "價格預警",
      Monitoring: "監控中",
      MonitoringRecord: "監控記錄",
      NewAlert: "新建預警",
      Cancel: "取消",
      PriceAlertSettings: "價格預警設置",
      Save: "保存",
      Load: "加載",
      TransactionType: "交易類型",
      PleaseSelect: "請選擇",
      NewestPrice: "最新價格",
      TriggerPrice: "觸發價格",
      DownTo: "下跌至",
      UpTo: "上漲至",
      RangePrice: "價格區間",
      Amplitude: "振幅",
      WarningDuration: "預警時長",
      Alert: "預警",
      ExpirationTime: "到期時間",
      OneMinute: "1分鐘",
      CloseManually: "手動關閉",
      WebSide: "Web端",
      AppSide: "App端",
      TriggeringConditions: "觸發條件",
      PriceAtSetup: "設置時價格",
      CreatedOrExpired: "創建/失效",
      Status: "狀態",
      Action: "操作",
      EffectiveTime: "生效時間",
    },
    LeverModal: {
      LevelTransfer: "杠杆劃轉",
      Transfer: "劃轉",
      Cancel: "取消",
      From: "從",
      To: "至",
      Currency: "幣種",
      AvailableFunds: "可用資金",
      TransferAmount: "劃轉數量",
      All: "全部",
      IsolateAccount: "逐倉帳戶",
      FullAccount: "全倉帳戶",
      SpotAccount: "現貨帳戶",
    },
    AIModal: {
      Spot: "現貨",
      Isolate: "逐倉",
      Full: "全倉",
      BrrowAndRepay: "借/還",
      PleaseLogin: "請先登錄！",
    },
    CiculateModal: {
      Spot: "現貨",
      Isolate: "逐倉",
      Full: "全倉",
      MarginBrrowAndRepay: "杠杆借還",
      Confirm: "確定",
      Cancel: "取消",
      LoanAmount: "借款數量",
      CurrentlyAvailableToBorrowTips:
        "系統可借充足情况下用戶帳戶當前最大可借額度",
      CurrentlyAvailableToBorrow: "當前可借",
      MaxAvailableToBorrowTips: "平臺限制的用戶當前等級可以借的額度",
      MaxAvailableToBorrow: "最大可借",
      HourlyInterestRate: "小時利率",
      HourlyInterestRateTips:
        "借入資產時會立即產生1小時的利息，並且每小時會記息一次（不滿一小時按一小時計算）",
      Loan: "借款",
      Repay: "還款",
      Available: "可用",
      Borrowed: "已借",
      NetAssets: "淨資產",
      CurrentInterest: "當前利息",
      RepaymentAmount: "還款數量",
      MaximumRepayable: "最大可還",
      OperationSuccessful: "操作成功",
      OperationFailed: "操作失敗",
    },
    LeverageToolsModal: {
      Isolate: "逐倉",
      Full: "全倉",
      MarginModel: "保證金模式",
      Confirm: "確定",
      MarginModelTips1: "調整保證金模式僅對當前合約生效。",
      FullMarginTipsTitle: "全倉保證金模式:",
      FullMarginTipsContent:
        "保證金資產相同的全倉倉位共亯該資產的全倉保證金。在强平事件中，交易者可能會損失全部該保證金和該保證金資產下的所有全倉倉位。",
      IsolateMarginTipsTitle: "逐倉保證金模式:",
      IsolateMarginTipsContent:
        "一定數量保證金被分配到倉位上。如果倉位保證金虧損到低於維持保證金的水准，倉位將被强平。在逐倉模式下，您可以為這個倉位添加和减少保證金。",
      Cancel: "取消",
      LeverageSetting: "杠杆率設定",
      CurrentLeveragePosition: "當前杠杆倍數最高可持有頭寸",
      CurrentLeveragePositionTips1: "杠杆調整將同時影響當前倉位和掛單的杠杆。",
      CurrentLeveragePositionTips2:
        "選擇超過[10x]杠杆交易會新增強行平倉風險，請注意相關風險。",
      ModelSetting: "模式設定",
      PositionModel: "倉位模式",
      OneWayPosition: "單向持倉",
      OneWayPositionTips: "單向持倉模式下，一個合約只允許持有一個方向的倉位。",
      TwoWayPosition: "雙向持倉",
      TwoWayPositionTips:
        "雙向持倉模式下，一個合約可允許同時持有多空兩個方向的倉位，並且同一合約下不同方向倉位風險對沖。",
      AssetModel: "資產模式",
      SingleModel: "單幣種保證金模式",
      SingleModelTips1: "僅支持使用合約的保證金資產交易U本位合約。",
      SingleModelTips2: "保證金資產相同的全倉倉位間盈虧互相抵消。",
      SingleModelTips3: "支持全倉和逐倉。",
      CombineModel: "聯合保證金模式",
      CombineModelTips1: "可跨保證金資產交易U本位合約。",
      CombineModelTips2: "不同保證金資產全倉倉位間盈虧相互抵消。",
      CombineModelTips3: "僅支持全倉。",
      PleaseSelectApi: "請先設定API",
      ActionSuccessful: "操作成功",
      ActionFailed: "操作失敗",
    },
    UserCountModal: {
      SelectSubAccount: "選擇交易子帳戶",
      Save: "保存",
      Cancel: "取消",
      SelectExchange: "選擇交易所",
      All: "全部",
      Main: "主",
      Ratio: "比例",
      MaxQty: "最大金額",
      MaxQtyTips: "請輸入最大可用金額",
      SyncRatio: "同步比例",
      AvailableBalance: "可用餘額",
      WalletBalance: "錢包餘額",
      ErrMsg: "錯誤的api key，請檢查此帳戶設定!",
    },
    TransferModal: {
      UniversalTransfer: "萬向劃轉",
      AssetTransfer: "資金劃轉",
      Transfer: "劃轉",
      TransferHistory: "劃轉記錄",
      Cancel: "取消",
      AccountSelect: "帳戶選擇",
      From: "從",
      To: "至",
      Currency: "幣種",
      AvailableFunds: "可用資金",
      TransferAmount: "劃轉數量",
      All: "全部",
      SpotAccount: "現貨帳戶",
      FutureAccount: "U合約帳戶",
      SwapAccount: "幣合約帳戶",
      FundsAccount: "資金帳戶",
    },
  },
  AIDashboard: {
    AIDashboardDetail: {
      Group: "交易分組",
      Export: "導出Excel",
      Search: "查詢",
      Optional: "自選",
      Market: "市場",
      Symbol: "交易對",
      Sort: "排名",
      Price: "價格",
      UpDown: "漲跌",
      TREND: "趨勢工具",
      STRONG_BACK: "強勢高拋低吸",
      SHOCK_GRID: "震蕩網格",
      SMART_SHOCK_GRID: "AI震蕩網格",
      SHOCK_REVERSE: "高拋低吸",
      MARKETMAKER: "做市策略",
      SOR: "智能路由",
      ARB_OPTION: "期權套利",
      ARB_FACTOR: "因子套利",
      TREND_CONTINUE_ROBOT: "趨勢二波",
      RATE_GRID: "匯率網格",
      STRONG_GRID: "強勢網格",
      MarkPrice: "標記價格",
      The24hHigh: "24h最高價",
      The24hLow: "24h最低價",
      The24hVolume: "24h成交量",
      The24hQty: "24h成交額",
      SelectEnvironment: "選擇運行環境",
      TestEnvironment: "測試環境",
      ProductionEnvironment: "生產環境",
      AwsEnvironment: "AWS環境",
      LinodeEnvironment: "Linode環境",
      Linode2Environment: "Linode2環境",
      Local211Environment: "本地211環境",
      SelectSubAccount: "選擇子帳戶",
      PleaseCancelUpdate: "請先取消更新",
      UnknownStrategyMsg: "未知策略",
      StartSuccessfulMsg: "啟動成功",
      StartFailedMsg: "啟動失敗",
      UpdateSuccessfulMsg: "更新成功",
      UpdateFailedMsg: "更新失败",
      UnknownSymbolMsg: "未知交易對",
      GetSubscribeListFailed: "獲取用戶訂閱清單失敗",
    },
    KChart: {
      HistoryMarks: "歷史委託",
      CurrentMarks: "當前委託",
    },
    Form: {
      MaxOrderAmount: "最大單量",
      ImpactLevel: "檔量",
      ImpactMultiple: "倍率 x",
      Auto: "自動",
      Manual: "手動",
      Level: "檔",
      LevelSetting: "訂單設定",
      LevelStart: "開始位置",
      LevelTotal: "掛單數",
      LevelInterval: "價格間隔",
      PositionSetting: "倉位設定",
      MultiplePositionsAvailable: "多頭頭寸",
      ShortPositionAvailable: "空頭頭寸",
      TargetPosition: "目標部位",
      AmountAvailable: "可用資金",
      AllAmountAvailable: "總可用資金",
      AvailableAmountOnce: "單次可用",
      RobotN: "只交易流動性前n名",
      PitchRatio: "間距比例",
      OrderStep: "拆單間距",
      OrderQuantity: "下單量",
      Isolate: "逐倉",
      Cross: "全倉",
      Spot: "現貨",
      Available: "可用",
      Borrowed: "已借",
      IsolatedMarginNetAssets: "逐倉淨資產",
      CrossMarginNetAssets: "全倉淨資產",
      SpotNetAssets: "現貨餘額",
      CanBeOpened: "可開",
      Balance: "餘額",
      CustomInstanceName: "自定義實例名稱",
      Reset: "重置",
      Cancel: "取消",
      NotLoggedIn: "未登錄",
      InsufficientVipLevel: "交易鎖定",
      Startup: "啟動",
      Update: "更新",
      SmartPrice: "SPF",
      Gear: "檔位",
      Window: "窗口",
      Cycle: "週期",
      LongCycle: "長週期",
      ShortCycle: "短週期",
      PriceGap: "價格間距",
      Minimum: "最小",
      Maximum: "最大",
      Volatility: "波動率",
      Trend: "趨勢",
      OrderBook: "OIF",
      OFIF: "OFIF",
      MaximumInventory: "最大庫存",
      EnableTimeSharing: "啟用分時",
      Clearance: "平倉",
      AllClearance: "全部平倉",
      OnlyMaker: "只做Maker",
      FollowBook: "跟隨盤口",
      AuthorizedObserver: "授權",
      PositionAvailable: "可用倉位",
      KlineCycle: "K線週期",
      OpenPositionQuantile: "開倉分位數",
      ClosePositionQuantile: "平倉分位數",
      Leverage: "杠杆",
      IsLong: "做多",

      IsUseCurrentPosition: "繼承倉位",
      IsNotReQuote: "只報價一次",
      IsOpen: "開倉",
      Side: "方向",
      OnlyLong: "允許做多",
      OnlyShort: "允許做空",
      LongAndShort: "多空雙開",
      StrongDecision: "強勢判定",
      BuyOrderSpacingRatio: "買單間距比例",
      SellOrderSpacingRatio: "賣單間距比例",
      EquivalentRatio: "等比",
      EquivalentDifferences: "等差",
      TakeProfitStopLoss: "止盈止損",
      TakeProfit: "止盈",
      StopLoss: "止損",
      PriceRange: "價格區間",
      HighPoint: "高點",
      LowPoint: "低點",
      BuyOrder: "買單",
      SellOrder: "賣單",
      PendingOrderLogarithm: "掛單對數",

      SymbolSettings: "交易對設定",
      RateGridLongSymbol: "做多交易對",
      RateGridTradeSymbol: "做空交易對",
      AddCurrentSymbol: "添加交易對",
      AddCurrentCurrency: "添加幣種",
      GoBack: "返回",
      Confirm: "確認",

      ExecConfig: "執行器配置",
      ExecMinOrderQty: "單筆最小下單量",
      ExecMaxSplitOrder: "最大拆單數",
      ExecOrderTimeout: "單筆訂單逾時時間",
      ExecTimeout: "完整逾時時間",
      ExecTimeoutTaker: "超時後是否吃單",
    },
    Command: {
      Running: "運行中",
      History: "歷史",
      ObserverRunning: "觀察者運行中",
      ObserverHistory: "觀察者歷史",

      Instance: {
        //期权
        Equity: "帳戶淨值",
        TotalEquityUsd: "帳戶資產淨值(美元)",
        Im: "初始保證金率",
        Mm: "維護保證金費率",
        TotalFloating_Profit_Loss_Usd: "損益(美元)",
        TotalProfit_Loss: "損益",
        Roi: "ROI",
        Total_Delta: "Delta",
        Total_Gamma: "Gamma",
        Total_Vega: "Vega",
        Total_Theta: "Theta",
        Total_Rho: "Rho",

        SubAccount: "子帳戶",
        All: "全部",
        SelectionPeriod: "選擇時間段",

        StopInstance: "停止實例",
        ChangeParameters: "修改參數",
        ReuseParameters: "複用參數",
        PositionOverview: "倉位概覽",
        TradeRecord: "交易記錄",
        InitiallyAvailable: "資金規模",
        TotalTransaction: "總成交額",
        StrategicBenefits: "已實現盈虧",
        TotalHoldingProfit: "未實現盈虧",
        TotalProfit: "總盈虧",
        CurrentPosition: "當前倉位",
        PositionDirection: "倉位方向",
        BuyAvgPrice: "買入均價",
        SellAvgPrice: "賣出均價",
        TransactionQty: "成交額",
        AverageHoldingPrice: "持倉均價",
        MarkPrice: "標記價格",
        Notional: "名義價值",
        HoldingProfit: "未實現盈虧",
        PolicyDetails: "策略詳情",
        IncomeLevel: "收益水位",
        HighIncomeLevel: "高水位",
        LowIncomeLevel: "低水位",
        MaximumDrawdown: "最大回撤",
        SingleProfit: "單盈利",
        MaximumProfit: "最大盈利",
        MinimumProfit: "最小盈利",
        MaximumLoss: "最大虧損",
        MinimumLoss: "最小虧損",
        ProfitAndLossTimes: "盈虧次數",
        ProfitTimes: "盈利次數",
        LossTimes: "虧損次數",
        ProfitAndLoss: "盈虧",
        TotalProfit2: "總盈利",
        TotalLoss: "總虧損",
        TotalRebate: "總手續費",
        NetProfit: "淨盈利",
        NetPosition: "淨持倉",
        ProfitAndLossRatio: "盈虧比",
        TransactionVolume: "成交量",
        Buy: "買",
        Sell: "賣",
        Total: "總計",
        TransactionRole: "交易角色",
        TakerCount: "Taker次數",
        MakerCount: "Maker次數",
        TakerQty: "Taker成交額",
        MakerQty: "Maker成交額",
        WinRate: "勝率",
        Detail: "查看詳情",
        SubAccountBalance: "子帳戶餘額",
        StrategyStatus: "策略狀態",
        AccountType: "交易類型",
        StartupTime: "啟動時間",
        StopConfirm: "確認停止該實例?",
        StopConfirmTips: "此操作無法撤銷!",
        Confirm: "確認",
        Cancel: "取消",
        ActionSuccess: "操作成功",
        ActionFailed: "操作失敗",

        CurrentPositionDirection: "當前倉位方向",
        Time: "時間",
        CumBuyU: "累計買入U量",
        CumBuyC: "累計買入幣量",
        AvgBuyPrice: "買入均價",
        CumSellU: "累計賣出U量",
        CumSellC: "累計賣出幣量",
        AvgSellPrice: "賣出均價",
        InitPosition: "初始持倉數量",
        CurrPosition: "當前持倉數量",
        CurrVolumn: "當前交易量",
        CurrValue: "當前幣種敞口價值上限",

        GroupId: "組ID",
        BuyAmount: "買入數量",
        SellAmount: "賣出數量",
        BuyQty: "買入金額",
        SellQty: "賣出金額",
        Profit: "盈虧",
        OrderFee: "手續費",
        StartTime: "開始時間",
        RunTime: "運行時長",
        Action: "操作",

        Day: "天",
        Hour: "小時",
        Minute: "分鐘",
        Second: "秒",

        ProfitModal: {
          YieldCurve: "收益曲線",
          CumulativeIncome: "累計收益",
          AverageValue: "平均值",
          SingleIncome: "單筆收益",
          IsLong: "做多",
          IsShort: "做空",
        },
        GroupModal: {
          IntraGroupTransactionRecord: "組內交易記錄",
          ShowOnlyTradedOrders: "僅顯示已成交訂單",
          OrderTime: "掛單時間",
          TransactionTime: "成交時間",
          TransactionDirection: "交易方向",
          TargetPrice: "目標價格",
          TargetAmount: "目標數量",
          AvgPrice: "成交均價",
          Vol: "成交數量",
          Qty: "成交額",
          Role: "成交角色",
          Status: "狀態",
          OrderFee: "手續費",
          Total: "總計",
          Record: "條記錄",
        },
      },
    },
  },

  ModalInfo: {
    ConfirmCancelExchange: "是否確認撤銷該掛單?",
    ConfirmCancelBail: "是否確認撤銷委託?",
    ConfirmCancelAllExchange: "是否確認撤銷全部掛單?",
    ConfirmMarketClosePosition: "是否確認市價平倉?",
    ConfirmOrder: "是否確認下單?",

    ConfirmTips: "此操作無法撤銷!",
    Yes: "是",
    No: "否",
    CancelAction: "取消動作",
    ActionSuccess: "操作成功",
    ActionFailed: "操作失敗",
  },
  ExchangeName: {
    Binance: "幣安",
    Okex: "OKX",
    Huobi: "火幣",
    Coinbase: "Coinbase",
    Deribit: "Deribit",
    AISelect: "AI選幣",
    Bybit: "BYBIT",
  },
  AccountType: {
    Spot: "現貨",
    Future: "U本位合約",
    Swap: "幣本位合約",
    Option: "期權",
    Index: "指數",
  },
  OkxAccountType: {
    SPOT: "現貨",
    SWAP: "永續",
    SWAPALL: "永續合約",
    FUTURES: "交割",
    FUTURESALL: "交割合約",
  },
  BybitAccountType: {
    SPOT: "現貨",
    LINEAR: "U本位合約",
    INVERSE: "反向合約",
    spot: "現貨",
    linear: "U本位合約",
    inverse: "反向合約",
    OPTION: "期權",
    Perpetual: "永續",
    Inverse: "反向",
  },
  Kchart: {
    SaveStudy: "保存指標",
    LoadStudy: "加載指標",
  },
  KChartBox: {
    SelectInterval: "選擇週期",
    Edit: "編輯",
    Save: "保存",
  },
  FuturesSymbol: {
    GC0W_CMX: "金主連",
    SI0W_CMX: "銀主連",
    HG0W_CMX: "銅主連",
    PL0W_NMX: "鉑主連",
    CL0W_NMX: "WTI原油主連",
    NG0W_NMX: "天然氣主連",
    RB0W_NMX: "RBOB汽油主連",
  },
  AstockSymbol: ASTOCK_LIST,
};
